import './App.css';
import Header from './components/header/Header.js';
import Sidebar from './components/sidebar/Sidebar';
import React from 'react';

import {Route, Routes, BrowserRouter as Router, Navigate} from 'react-router-dom';

import AppBlueCarRev from './pages/blog/blueCarRev';
import Appasgb from './pages/sidebarItems/asgb';
import Appbwr006 from './pages/sidebarItems/bwr006';
import Appbwr007 from './pages/sidebarItems/bwr007';
import Appmkd from './pages/sidebarItems/mkd';
import Appyemk from './pages/sidebarItems/yemk';
import Appew from './pages/sidebarItems/ew';
import Appmkep from './pages/sidebarItems/mkep';
import AppDiaryEntry1 from './pages/blog/diaryEntry1';
import Appgreb from './pages/sidebarItems/greb';
import AppAmerikaRev from './pages/blog/amerikaRev';
import AppPoem1 from './pages/blog/p1';


const App = () => {


    return (
      <div className="App">
        
        <Router>
        
        <Routes>
            <Route path="/" element={ <Navigate to="/GHOSTRECONEPHEMERALBOOTLEG" />}/>
            <Route path='/DE1' element={<AppBlueCarRev />} />
            <Route path='/DE2' element={<AppDiaryEntry1 />} />
            <Route path='/DE3' element={<AppAmerikaRev />} />
            <Route path='/DE4' element={<AppPoem1 />} />
            <Route path='/BRISKWALKRADIO007' element={<Appbwr007 />} />
            <Route path='/ASECONDGUNBALAMII' element={<Appasgb />} />
            <Route path='/BRISKWALKRADIO006' element={<Appbwr006 />} />
            <Route path='/MKDRIVER' element={<Appmkd />} />
            <Route path='/YOUTHETERNAL' element={<Appyemk />} />
            <Route path='/ELEVENWOUND' element={<Appew />} />
            <Route path='/MMETALKNIGHTTEP' element={<Appmkep />} />
            <Route path='/GHOSTRECONEPHEMERALBOOTLEG' element={<Appgreb />} />
            <Route path='/NEWESTSONG' element={ <Navigate to="/GHOSTRECONEPHEMERALBOOTLEG" />} />

          </Routes>
        
        </Router>
        </div>
    );
}
export default App;