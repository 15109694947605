import React from 'react';
import Typewriter from "typewriter-effect";
import Sidebar from '../../components/sidebar/Sidebar';
import Header from '../../components/header/Header';
import { useMediaQuery } from 'react-responsive';

function Appbwr006(){
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    return (
        <div>
            {isDesktopOrLaptop &&
                <div>
                    <div className='headerContainer' style={{position: 'absolute'}}>
                        <Header/>
                    </div>
                    <div className= "SidebarContainer" style={{position: 'absolute'}}>
                        <Sidebar/>
                    </div>
                    <div className= "deskContainerBWR6" style={{position: 'absolute'}}> 
                        <div className="london" style={{position: 'fixed'}}>
                        
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter
                                    .changeDelay(10)
                                    .typeString(
                                        "1 hour of electronic music from MIKI for briskwalk RADIO<br><br><b>GLINT</b>⠀⠀⠀2 TIMES TOO<br><b>LADY Z</b>⠀⠀⠀NICELY<br><b>WEV.</b>⠀⠀⠀YEARN<br><b>PERFECT CHAO⠀⠀AMAL⠀⠀⠀</b>PASSION<br><b>PINKPANTHERESS⠀⠀BETTERTOGETHER<br>TUCHSCREEN</b>⠀⠀⠀DON'T U KNO?<br><b>LOLA</b>⠀⠀⠀4 DA LADIEZ<br><b>LIL KEV</b>⠀⠀⠀STEP UP<br><b>CAKEDOG</b>⠀⠀⠀THEY WAS SLEEPIN'<br><b>KAMBABA JASPER</b>⠀⠀⠀KUNTI KAKASHI<br><b>ARK</b>⠀⠀⠀WSUP!<br><b>PERFECT CHAO⠀⠀⠀SANCTUARY</b><br><b>KWES E⠀⠀SCRUZ</b>⠀⠀⠀NURSERY<br><b>VEGYN</b>⠀⠀⠀I DON'T OWE U NYTHING<br><b>MIKE</b>⠀⠀⠀TAPESTRY<br><b>RADA</b>⠀⠀⠀END ME<br><b>SILV-O</b>⠀⠀⠀'IT IZ'<br><b>PHANSYPHISH</b>⠀⠀⠀I HAD SO MUCH FUCKING FUN<br><b>SWAMI SOUND⠀⠀KELELA</b>⠀⠀⠀WAITIN<br><b>NEVERLAND</b>⠀⠀⠀VLVT<br><b>LOUKEMAN</b>⠀⠀⠀CPU100<br><b>PETE BRADOCK</b>⠀⠀⠀DEEPBURNT<br><b>EARL SWEATSHIRT</b>⠀⠀⠀FALLING OUT THE SKY<br><b>INSTUPENDO⠀⠀OLI XL</b>⠀⠀⠀VITAMIN<br><b>DEAN BLUNT</b>⠀⠀⠀MUGU<br><b>LOLA</b>⠀⠀⠀WOULD U MIND JUNGLE<br><b>TOM HENNESSY</b>⠀⠀⠀BEAUTIFUL<br>"
                                    )
                                    .start();
                                }}
                            />
                            <a href='https://soundcloud.com/mmmiiikkkiii/sets/briskwalk-radio' target='_blank' rel='noreferrer'>
                                + listen
                            </a>
                            <text><br /> <br /><br /><br /><br /></text>
                            
                        </div>
                    </div>
                </div>
            }
            {isTabletOrMobile&&
                <div>
                    
                    <div className= "deskContainerMob" style={{position: 'absolute'}}> 
                        <div className="london" style={{position: 'fixed'}}>
                        <text><br /> <br /><br /><br /><br /><br /><br /></text>
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter
                                    .changeDelay(10)
                                    .typeString("1 hour of electronic music from MIKI for briskwalk<br>RADIO<br>")
                                    .start();
                                }}
                            />
                            <a href='https://soundcloud.com/mmmiiikkkiii/sets/briskwalk-radio' target='_blank' rel='noreferrer'>
                                + listen
                            </a>
                        </div>
                    </div>
                    <div className= "SidebarContainerMob" style={{position: 'absolute'}}>
                        <Sidebar/>
                    </div>
                    <div className='headerContainerMob' style={{position: 'absolute'}}>
                        <Header/>
                    </div>
                </div>
            }


        </div>

        
        
    )
}

export default Appbwr006;