import React from 'react';
import Typewriter from "typewriter-effect";
import {Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Header2 from '../../components/header/Header2';



function AppPoem1(){
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    return (
        <div>
            {isDesktopOrLaptop &&
                <div>
                    <div className='headerContainer' style={{position: 'absolute'}}>
                    <Header2/>
                </div>

                <div className= "deskContainerBlog">
                <div className="london" style={{position: 'absolute'}}>
                    
                <Link to="/NEWESTSONG">
                     [BACK TO WORKS] <br/  >
                </Link> 
                <Link to='/DE3'>
                [PREV]
                </Link>
                
                ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀[4] 13/8/23 + 777532687369833283767987<br/>
                 ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                 <a href='https://www.youtube.com/watch?v=d_8wxICeIPQ&pp=ygULRGFpZ28gSWtlbm8%3D' target='_blank' rel='noreferrer'>
                (+)
                </a>
                ⠀
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .changeDelay(1)
                            .typeString("<br><br>⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀<b>''<b>")

                            .typeString("<br>⠀⠀⠀⠀⠀⠀⠀I⠀PU T⠀MY⠀<b>HE AR T</b>⠀IN⠀TH E⠀MI X,<br>")
                            .pauseFor(1000)
                            .typeString("<br>⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀SO UN D⠀PI ER CE S⠀MY⠀<b>SO UL</b>,<br>")
                            .pauseFor(1000)
                            .typeString("<br>⠀⠀⠀ DR UM S⠀PO SS ES S⠀MY⠀<b>MI ND⠀+⠀BO DY</b>,<br>")
                            .pauseFor(1000)
                            .typeString("<br>+⠀TH E⠀TR AN SI EN TS⠀DA NC E⠀@⠀MY⠀<b>WI LL.</b>")
                            .typeString("<br><br>⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀<b>''</b>")
                            .pauseFor(1000)
                            .start()
                    }}
                />
                
                
            </div>
            </div>
            </div>
}

{isTabletOrMobile &&
    <div>
    <div className='headerContainerMob' style={{position: 'absolute'}}>
                    <Header2/>
                </div>
        <div className= "deskContainerBlogMob">
        <div className="london" style={{position: 'absolute'}}>
        <Link to="/NEWESTSONG">
                     [BACK TO WORKS] <br/  >
                </Link> 
                
                {
                
                <Link to="/DE3">
                 [PREV] <br/  >
                 </Link> 
                }
                [4] 13/8/23 + 777532687369833283767987<div></div>
                
                
                <a href='https://www.youtube.com/watch?v=d_8wxICeIPQ&pp=ygULRGFpZ28gSWtlbm8%3D' target='_blank' rel='noreferrer'>
                (+)
                </a>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .changeDelay(1)
                            .typeString("<br><br>⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀<b>''<b>")

                            .typeString("<br><br><br>⠀⠀⠀⠀⠀⠀⠀I⠀PU T⠀MY⠀<b>HE AR T</b>⠀IN⠀TH E⠀MI X,<br>")
                            .pauseFor(1000)
                            .typeString("<br>⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀SO UN D⠀PI ER CE S⠀MY⠀<b>SO UL</b>,<br>")
                            .pauseFor(1000)
                            .typeString("<br>⠀⠀⠀ DR UM S⠀PO SS ES S⠀MY⠀<b>MI ND⠀+⠀BO DY</b>,<br>")
                            .pauseFor(1000)
                            .typeString("<br>+⠀TH E⠀TR AN SI EN TS⠀DA NC E⠀@⠀MY⠀<b>WI LL.</b><br>")
                            .typeString("<br><br>⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀<b>''</b>")
                            .pauseFor(1000)
                            .start()
                    }}
                />
                
                
            </div>
            </div>
            </div>
}
            </div>

    );
}

export default AppPoem1;