import React from 'react';
import Typewriter from "typewriter-effect";
import {Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Header2 from '../../components/header/Header2';


function AppDiaryEntry1(){
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    return (
        <div>
            {isDesktopOrLaptop &&
                <div>
                    <div className='headerContainer' style={{position: 'absolute'}}>
                    <Header2/>
                </div>

                <div className= "deskContainerBlog">
                <div className="london" style={{position: 'absolute'}}>
                    
                <Link to="/NEWESTSONG">
                     [BACK TO WORKS] <br/  >
                </Link> 
                <Link to='/DE1'>
                [PREV]
                </Link>
                ⠀⠀⠀⠀⠀⠀⠀[2] 30/6/23 + A WALK DOWN A PATH YOU'VE SEEN BEFORE⠀⠀⠀⠀⠀⠀⠀
                <Link to='/DE3'>
                [NEXT]
                </Link> <br/>
                ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                <a href='https://soundcloud.com/triplexlarge/ue-mbicre-mix' target='_blank' rel='noreferrer'>
                (que + 3 X L ambient mix)
                </a>
                ⠀
                <a href='https://www.youtube.com/watch?v=OQSNhk5ICTI&t=1s&pp=ygUOZG91YmxlIHJhaW5ib3c%3D' target='_blank' rel='noreferrer'>
                (double rainbow video)
                </a>
                
                
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .changeDelay(1)
                            .typeString("<br>Since I've lived @ <b>++++++++</b>, there's been a canal path I've driven past in my<br>mums car, hidden under a motorway that I nevr paid much attention 2.<br><br>")
                            .pauseFor(1000)
                            .typeString("Today, I decided 2 try + find out how I cld walk down it!<br><br>")
                            .pauseFor(1000)
                            .typeString("After walking 4 about 30 minutes, I found the entrance hidden by sum bushes on the<br>motorway + after walking dwn sum steps I had found it")
                            .pauseFor(1000)
                            .typeString(" (wasn't as hard as I thought <br>it'd b TBH). <br><br>")
                            .pauseFor(1000)
                            .typeString("It's as I stated, a canal path, nothing special but I think I needed this walk today.<br><br>")
                            .pauseFor(1000)
                            .typeString("The weather was nice + I was listening 2 the new ambient 3XL mix by <b>que</b>. <br><br> ")
                            .pauseFor(1000)
                            .typeString("As I continued 2 walk, every 20 minutes or so, I'd come across people <b>fishing, riding<br>bikes</b>, even a family on a <b>small narrowboat</b>.  <br><br>")
                            .pauseFor(1000)
                            .typeString("Sum <b>ducks, dragonflies, an abandoned Lime bike, a broken bench</b> that I sat @ 4 sum<br>time b4 continuing. <br><br>")
                            .pauseFor(1000)
                            .typeString("The canal seemed 2 go on forever + the more I walked, the more I got lost. <br><br>")
                            .pauseFor(1000)
                            .typeString("Eventually, I met road, realised where I was + made my way back, whilst being blown<br>away by watevr track was playing @ 55:00 of the 3XL mix. <br><br>")
                            .pauseFor(1000)
                            .typeString("That <b>double rainbow vid </b>sounds funny asl but seems very innocent!<br><br>")
                            .pauseFor(1000)
                            .typeString("2 summarise, u shld walk down those roads that seem familiar but r unexplored<br>+ b suprised @ wats been waiting 4 u frm b4 u were born!<br><br>")
                            .pauseFor(1000)
                            .typeString("Mayb it was the weather + music or sumn, but the walk felt spiritual, + I'd highly<br>recommend u take some time out 2<b> reconnect wiv nature</b> every now and again (as <br>corny as it sounds. I'd tell u the canal path I walked down but, unfortunately, I am a<br>professional gatekeeper) <br><br>")
                            .pauseFor(1000)
                            .changeDelay(200)
                            .typeString("+ <b>MIKI</b>")
                            .pauseFor(100000)
                            .typeString("<br> <br> wait, what? <br>+ <br>https://soundcloud.com<br>/mmmiiikkkiii<br>/oh-my-love-from-long-range<br>/s-0vlj03KWb51?si=eaf0b29cf8f24442bf43a8d86c7ac430&utm<br>_source=clipboard&utm_medium=text&utm_campaign=social_sharing")
                            .start()
                    }}
                />
                
                
            </div>
            </div>
            </div>
}

{isTabletOrMobile &&
    <div>
    <div className='headerContainerMob' style={{position: 'absolute'}}>
                    <Header2/>
                </div>
        <div className= "deskContainerBlogMob">
        <div className="london" style={{position: 'absolute'}}>
        <Link to="/NEWESTSONG">
                     [BACK TO WORKS] <br/  >
                </Link> 
                {
                <Link to="/DE1">
                 [PREV]
                 </Link> 
                }⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                <Link to="/DE3">
                 [NEXT] <br/  >
                 </Link> 
                
                [2] 30/6/23 + A WALK DOWN A PATH YOU'VE<br/>SEEN BEFORE<div></div>                
                <a href='https://soundcloud.com/triplexlarge/ue-mbicre-mix' target='_blank' rel='noreferrer'>
                (que + 3 X L ambient mix)
                </a>
                ⠀
                <a href='https://www.youtube.com/watch?v=OQSNhk5ICTI&t=1s&pp=ygUOZG91YmxlIHJhaW5ib3c%3D' target='_blank' rel='noreferrer'>
                (double rainbow<br/>video)
                </a>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                        .changeDelay(1)
                        .typeString("<br>Since I've lived @ <b>++++++++</b>, there's<br>been a canal path I've driven past in my<br>mums car, hidden under a motorway that I<br>nevr paid much attention 2.<br><br>")
                        .pauseFor(1000)
                        .typeString("Today, I decided 2 try + find out how I cld<br>walk down it!<br><br>")
                        .pauseFor(1000)
                        .typeString("After walking 4 about 30 minutes, I found the<br>entrance hidden by sum bushes on the<br>motorway + after walking down sum steps I<br>had found it")
                        .pauseFor(1000)
                        .typeString(" (wasn't as hard as I thought it'd<br>b TBH). <br><br>")
                        .pauseFor(1000)
                        .typeString("It's as I stated, a canal path, nothing<br>special but I think I needed this walk 2day.<br><br>")
                        .pauseFor(1000)
                        .typeString("The weather was nice + I was listening 2 the<br>new ambient 3XL mix by <b>que</b>. <br><br> ")
                        .pauseFor(1000)
                        .typeString("As I continued 2 walk, every 20 minutes or so,<br>I'd come across people <b>fishing, riding bikes</b>,<br> even a family on a <b>small narrowboat</b>.  <br><br>")
                        .pauseFor(1000)
                        .typeString("Sum <b>ducks, dragonflies, an abandoned Lime<br>bike, a broken bench</b> that I sat @ 4 sum time<br>b4 continuing. <br><br>")
                        .pauseFor(1000)
                        .typeString("The canal seemed 2 go on forever + the more<br>I walked, the more I got lost. <br><br>")
                        .pauseFor(1000)
                        .typeString("Eventually, I met road, realised where I was<br>+ made my way back, whilst being blown<br>away by watevr track was playing @ 55:00<br>of the 3XL mix. <br><br>")
                        .pauseFor(1000)
                        .typeString("That <b>double rainbow vid </b> that was sampled<br>sounds funny asl but seems very innocent!<br><br>")
                        .pauseFor(1000)
                        .typeString("2 summarise, u shld walk down those<br>roads that seem familiar but r unexplored<br>+ b suprised @ wats been waiting 4 u frm b4<br>u were born!<br><br>")
                        .pauseFor(1000)
                        .typeString("Mayb it was the weather + music or sumn,<br> but the walk felt spiritual, + I'd highly<br>recommend u take some time out 2<b> reconnect<br>wiv nature</b> every now + again (as <br>corny as it sounds. I'd tell u the canal path<br>I walked down but, unfortunately, I am a<br>professional gatekeeper) <br><br>")
                        .pauseFor(1000)
                        .changeDelay(200)
                        .typeString("+ <b>MIKI</b><br> <br><br> <br> <br> <br><br> <br> <br> <br>  ")
                        .pauseFor(100000)
                        .typeString("wait, what? <br>+ <br>https://soundcloud.com<br>/mmmiiikkkiii<br>/oh-my-love-from-long-range<br>/s-0vlj03KWb51?si=eaf0b29cf8f24442bf43a8d86c7ac430&utm<br>_source=clipboard&utm_medium=text&utm_campaign=social_sharing<br> <br><br> <br> <br> <br><br> <br> <br> <br>")
                        .start()
                    }}
                />
                
                
            </div>
            </div>
            </div>
}
            </div>

    );
}

export default AppDiaryEntry1;