import React from 'react';
import Typewriter from "typewriter-effect";
import {Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Header2 from '../../components/header/Header2';



function AppAmerikaRev(){
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    return (
        <div>
            {isDesktopOrLaptop &&
                <div>
                    <div className='headerContainer' style={{position: 'absolute'}}>
                    <Header2/>
                </div>

                <div className= "deskContainerBlog">
                <div className="london" style={{position: 'absolute'}}>
                    
                <Link to="/NEWESTSONG">
                     [BACK TO WORKS] <br/  >
                </Link> 
                <Link to='/DE2'>
                [PREV]
                </Link>
                
                ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀[3] 23/7/23 + FRANK KAFKA / AMERIKA⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                
                <Link to='/DE4'>
                [NEXT]
                </Link><br/>
                 ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                 <a href='https://wastestorelondon.com/Home-1' target='_blank' rel='noreferrer'>
                (waste store london)
                </a>
                ⠀
                <a href='https://sebrightarms.com/' target='_blank' rel='noreferrer'>
                 (sebright arms)
                </a>
                ⠀
                <a href='https://en.wikipedia.org/wiki/Franz_Kafka' target='_blank' rel='noreferrer'>
                 (kafka wiki)
                </a>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .changeDelay(1)
                            .typeString("<br>This book was pretty gd!<br><br>")
                            .pauseFor(1000)
                            .typeString("I found out about this book when I met my friend, <b>TIM</b>.<br><br>")
                            .pauseFor(1000)
                            .typeString("When I first met him, I randomly ran in2 him @ the <b>WASTE STORE</b> on Hackney Road (when<br>he used to work there) + we began trying 2 establish an open deck DJ event every<br>week in Shoreditch every Sunday. <br><br>")
                            .pauseFor(1000)
                            .typeString("So, every week, I’d trek on the Underground frm North West London 2 East, drop in2<br>the store 2 say wats up + make my way 2 the venue, <b>SEBRIGHT ARMS</b>.<br><br>")
                            .pauseFor(1000)
                            .typeString("I remember seeing him sometime in Winter '22 reading this book wen I walked in + he<br>recommended it 2 me, telling me that <b>KAFKA</b> was one of his favourite authors.<br><br>")
                            .pauseFor(1000)
                            .typeString("I ordered it in April + hv only jst finished it. <br><br> ")
                            .pauseFor(1000)
                            .typeString("2 summarise the book wthout revealing 2 much, its about a guy tht gets sent to<br>New York frm Germany due to a scandal tht led to his family essentially disowning him <br>+ he goes through sum inconveniences + setups during his stay. <br><br>")
                            .pauseFor(1000)
                            .typeString("I thought it'd b a sick coming of age story but I was unaware that the story doesn't<br>even finish.<br><br>")
                            .pauseFor(1000)
                            .typeString("I wld b lying if I said I wasn’t upset at the point where the book cut off, cus the MC,<br><b>KARL</b>, had made a decision that I thought wasnt consistent with his character. <br><br>")
                            .pauseFor(1000)
                            .typeString("I didnt know that the Author, Kafka, had left the book unfinished + had died. :(<br><br>")
                            .pauseFor(1000)
                            .typeString("THOUGH, with that being said, I did enjoy the book especially the later half + I’d<br>recommend people to read it!<br><br>")
                            .pauseFor(1000)
                            .changeDelay(200)
                            .typeString("+ <b>MIKI</b>")
                            .pauseFor(100000)
                            .typeString("<br> <br> CALL UR LOVED ONES >:( <br>+ <br>https://soundcloud.com/<br>mmmiiikkkiii/<br>basher-5/<br>s-qVShBe03506?si=dfdcaccfe4dc433993d878fd11a62d23&<br>utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing")
                            .start()
                    }}
                />
                
                
            </div>
            </div>
            </div>
}

{isTabletOrMobile &&
    <div>
    <div className='headerContainerMob' style={{position: 'absolute'}}>
                    <Header2/>
                </div>
        <div className= "deskContainerBlogMob">
        <div className="london" style={{position: 'absolute'}}>
        <Link to="/NEWESTSONG">
                     [BACK TO WORKS] <br/  >
                </Link> 
                
                {
                
                <Link to="/DE2">
                 [PREV]
                 </Link> 
                }⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
                <Link to="/DE4">
                 [NEXT] <br/  >
                 </Link> 
                
                [3] 23/7/23 + FRANK KAFKA / AMERIKA<div></div>
                
                
                <a href='https://wastestorelondon.com/Home-1' target='_blank' rel='noreferrer'>
                (waste store london)
                </a>
                ⠀
                <a href='https://sebrightarms.com/' target='_blank' rel='noreferrer'>
                 (sebright arms)
                </a>
                ⠀
                <a href='https://en.wikipedia.org/wiki/Franz_Kafka' target='_blank' rel='noreferrer'>
                 (kafka<br  />wiki)
                </a>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .changeDelay(1)
                            .typeString("<br>This book was pretty gd!<br><br>")
                            .pauseFor(1000)
                            .typeString("I found out about this book when I met my<br>friend, <b>TIM</b>.<br><br>")
                            .pauseFor(1000)
                            .typeString("When I first met him, I randomly ran in2 him<br>@ the <b>WASTE STORE</b> on Hackney Road (when<br>he used to work there) + we began trying 2<br>establish an open deck DJ event every week<br>in Shoreditch every Sunday. <br><br>")
                            .pauseFor(1000)
                            .typeString("So, every week, I’d trek on the Underground<br>frm North West London 2 East, drop in2 the<br>store 2 say wats up + make my way 2 the<br>venue, <b>SEBRIGHT ARMS</b>.<br><br>")
                            .pauseFor(1000)
                            .typeString("I remember seeing him sometime in Winter '22<br>reading this book wen I walked in + he<br>recommended it 2 me, telling me that <b>KAFKA</b><br> was one of his favourite authors.<br><br>")
                            .pauseFor(1000)
                            .typeString("I ordered it in April + hv only jst finished it. <br><br> ")
                            .pauseFor(1000)
                            .typeString("2 summarise the book wthout revealing 2<br>much, its about a guy tht gets sent to New York<br>frm Germany due to a scandal tht led to his<br>family disowning him + he goes through sum inconveniences<br>+ setups during his stay. <br><br>")
                            .pauseFor(1000)
                            .typeString("I thought it'd b a sick coming of age story but<br>I was unaware that the story doesn't even<br>finish.<br><br>")
                            .pauseFor(1000)
                            .typeString("I wld b lying if I said I wasn’t upset at the point<br>where the book cut off, cus the MC, <b>KARL</b>, had<br>made a decision tht I thought wasn't consistent<br>wth his character. <br><br>")
                            .pauseFor(1000)
                            .typeString("I didnt know that the Author, Kafka, had left the<br>book unfinished + had died. :(<br><br>")
                            .pauseFor(1000)
                            .typeString("THOUGH, with that being said, I did enjoy the<br>book especially the later half + I’d recommend<br>people to read it!<br><br>")
                            .pauseFor(1000)
                            .changeDelay(200)
                            .typeString("+ <b>MIKI</b><br> <br><br> <br> <br> <br><br> <br> <br> <br>")
                            .pauseFor(100000)
                            .typeString("<br> <br> CALL UR LOVED ONES >:( <br>+ <br>https://soundcloud.com/<br>mmmiiikkkiii/<br>basher-5/<br>s-qVShBe03506?si=dfdcaccfe4dc433993d878fd11a62d23&<br>utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing")
                            .start()
                    }}
                />
                
                
            </div>
            </div>
            </div>
}
            </div>

    );
}

export default AppAmerikaRev;